import request from '@/utils/request';

const PATH = '/interests';

export const getInterestsList = (role: string) => request({
  url: PATH,
  method: 'get',
  params: {
    roleCode: role,
  },
});
