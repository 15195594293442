
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import Pagination from '@/components/board/pagination.vue';
import { getBlogList } from '@/api/myBlog';
import { getRoleList } from '@/api/role';
import { IBlogList, IInterests, IRole } from '@/types';
import { addInterestCompany, deleteInterestCompany, getMyInterestCompanyList } from '@/api/interestCompany';
import { UserModule } from '@/store/user';
import MyBlog from '@/components/blog/index.vue';
import { getInterestsList } from '@/api/interests';

@Component({
  name: 'BlogList',
  components: {
    Pagination,
    MyBlog,
  },
})
export default class extends Vue {
  @Prop({ required: true }) type!: string

  mounted() {
    this.init();
  }

  @Watch('searchInterestsList')
  private handleChangeMenu() {
    this.init();
    this.listQuery = {
      page: 0,
      size: 15,
      roleCode: '',
      searchValue: '',
      orderBy: 'update',
      interestsList: '',
    };
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  get isLogined() {
    return UserModule.token;
  }

  private blogList: IBlogList[] = [];

  private blogVisible = false;

  private blogNickName = '';

  private listQuery = {
    page: 0,
    size: 15,
    roleCode: '',
    searchValue: '',
    orderBy: 'update',
    interestsList: '',
  };

  private class = ''

  private listTotal = 0;

  private totalPages = 0;

  private role: IRole | null = null;

  private interestsList: IInterests[] = [];

  private interestCompanyList: string[] = [];

  private searchInterestsList: string[] = [];

  private init() {
    let index = 0;
    if (this.type === 'support') index = 2;
    if (this.type === 'invest') index = 3;
    if (this.$route.query) {
      this.listQuery = {
        ...this.listQuery,
        ...this.$route.query,
      };
    }
    if (this.$route.query.interestsList && typeof (this.$route.query.interestsList) === 'string') {
      this.searchInterestsList = (this.$route.query.interestsList as any).split(',');
    }
    getRoleList().then((res) => {
      if (res.data[index]) {
        const role = res.data[index];
        this.role = role;
        this.listQuery.roleCode = role.roleCode;
        getInterestsList(res.data[index].roleCode).then((response) => {
          this.interestsList = response.data;
          this.getBlogList();
        });
      }
    });
    getMyInterestCompanyList().then((res) => {
      this.interestCompanyList = res.data;
    });
  }

  private getBlogList() {
    if (this.searchInterestsList && this.searchInterestsList.length > 0) {
      this.listQuery.interestsList = this.searchInterestsList.join(',');
    }
    getBlogList(this.listQuery).then((res) => {
      this.blogList = res.data.content;
      this.listTotal = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private handleClickAll() {
    setTimeout(() => {
      if (this.searchInterestsList.indexOf('all') < 0) {
        this.searchInterestsList = [];
      } else {
        this.interestsList.forEach((i) => {
          this.searchInterestsList.push(i.uid);
        });
      }
    }, 100);
  }

  private isInterestCompany(uid: string) {
    return this.interestCompanyList.indexOf(uid) > -1;
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getBlogList();
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getBlogList();
  }

  private getOnclass(order: string) {
    if (this.listQuery.orderBy === order) return 'sort_on';
    return '';
  }

  private handleClickOrder(order: string) {
    this.listQuery.orderBy = order;
    this.getBlogList();
  }

  private handleClickInterest(uid: string, follow: boolean) {
    if (follow) {
      addInterestCompany(uid).then(() => {
        this.interestCompanyList.push(uid);
      });
    } else {
      deleteInterestCompany(uid).then(() => {
        const index = this.interestCompanyList.indexOf(uid);
        if (index > -1) this.interestCompanyList.splice(index, 1);
      });
    }
  }

  private getRouterName() {
    if (this.type === 'support') return 'SupportDetail';
    if (this.type === 'invest') return 'InvestDetail';
    return '';
  }

  private handleClickBlog(nickName: string) {
    this.blogNickName = nickName;
    this.blogVisible = !this.blogVisible;
  }

  private searchReset() {
    this.listQuery.searchValue = '';
  }

  private handleClass() {
    this.class = 'sub2_1';
  }

  private formatNumber(value: number) {
    const stringValue = value.toString();

    return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
