
import { Vue, Component, Watch } from 'vue-property-decorator';
import BlogList from '@/components/blog/list.vue';

@Component({
  name: 'SupportIndex',
  components: {
    BlogList,
  },
})
export default class extends Vue {

}
